<template>
	<a-space direction="vertical">
		<a-card :title="cardTitle">
			<a-form-model ref="form" :model="formdata" :rules="rules" v-bind="layout">
				<a-form-model-item label="商品名称" prop="productName">
					<span v-if="readonly">{{ formdata.productName }}</span>
					<a-input v-else v-model="formdata.productName" placeholder="请输入商品名称" />
				</a-form-model-item>
				<a-form-model-item label="商品特点" prop="productPoint">
					<span v-if="readonly">{{ formdata.productPoint }}</span>
					<a-input v-else v-model="formdata.productPoint" placeholder="请输入商品特点" />
				</a-form-model-item>
				
				<a-form-model-item label="分享推荐语" prop="recommendedLanguage">
					<span v-if="readonly">{{ formdata.recommendedLanguage || '' }}</span>
					<a-input v-else v-model="formdata.recommendedLanguage" placeholder="请输入分享推荐语" />
				</a-form-model-item>
				<!-- <a-form-model-item label="供货价" prop="cost">
					<span v-if="readonly">{{ formdata.cost }}</span>
					<a-input v-else v-model="formdata.cost" placeholder="请输入供货价" />
				</a-form-model-item> -->
				<a-form-model-item label="销售价" prop="salePrice">
					<span v-if="readonly">{{ formdata.salePrice }}</span>
					<a-input v-else v-model="formdata.salePrice" placeholder="请输入销售价" />
				</a-form-model-item>
				<a-form-model-item label="市场价" prop="marketPrice">
					<span v-if="readonly">{{ formdata.marketPrice }}</span>
					<a-input v-else v-model="formdata.marketPrice" placeholder="请输入市场价" />
				</a-form-model-item>
				<a-form-model-item label="销量" prop="volume">
					<span v-if="readonly">{{ formdata.volume }}</span>
					<a-input v-else v-model="formdata.volume" placeholder="请输入销量" />
				</a-form-model-item>
				<a-form-model-item label="分享数量" prop="shareNum">
					<span v-if="readonly">{{ formdata.shareNum }}</span>
					<a-input v-else v-model="formdata.shareNum" placeholder="请输入分享数量" />
				</a-form-model-item>
				<a-form-model-item label="赠送起始值" prop="giveStart">
					<span v-if="readonly">{{ formdata.giveStart }}</span>
					<a-input v-else v-model="formdata.giveStart" placeholder="请输入赠送起始值" />
				</a-form-model-item>
				<a-form-model-item label="赠送倍率" prop="giveMultiple">
					<span v-if="readonly">{{ formdata.giveMultiple }}</span>
					<a-input v-else v-model="formdata.giveMultiple" placeholder="请输入赠送倍率(例如满五送一)" />
				</a-form-model-item>
				<a-form-model-item label="提货起始值" prop="pickUpNum">
					<span v-if="readonly">{{ formdata.pickUpNum }}</span>
					<a-input v-else v-model="formdata.pickUpNum" placeholder="请输入提货起始值" />
				</a-form-model-item>
				<a-form-model-item label="提货步长" prop="pickUpStep">
					<span v-if="readonly">{{ formdata.pickUpStep }}</span>
					<a-input v-else v-model="formdata.pickUpStep" placeholder="请输入提货步长" />
				</a-form-model-item>
				<a-form-model-item label="排序" prop="sort">
					<span v-if="readonly">{{ formdata.sort || '-' }}</span>
					<a-input v-else v-model="formdata.sort" placeholder="请输入排序" />
				</a-form-model-item>
				<a-form-model-item label="线下购买礼包图片" prop="offlineUpgrade" help="注: 图片比例:1:1,建议尺寸:200x200">
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.offlineUpgrade" /></div>
					</div>
					<WsUpload v-else :url="formdata.offlineUpgrade" @success="e => (formdata.offlineUpgrade = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="线下补货图片" prop="offlineReplenish" help="注: 图片比例:1:1,建议尺寸:200x200">
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.offlineReplenish" /></div>
					</div>
					<WsUpload v-else :url="formdata.offlineReplenish" @success="e => (formdata.offlineReplenish = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="商品分享图" prop="sharePic" help="注: 图片比例:4:3,建议尺寸:400x300">
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.sharePic" /></div>
					</div>
					<WsUpload v-else :url="formdata.sharePic" @success="e => (formdata.sharePic = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="APP商品封面图" prop="smallPic" help="注: 图片比例:1:1,建议尺寸:200x200">
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.smallPic" /></div>
					</div>
					<WsUpload v-else :url="formdata.smallPic" @success="e => (formdata.smallPic = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="商品横幅图" prop="banner" help="注: 图片比例:16:9,建议尺寸:702x395">
					<div class="details" v-if="readonly">
						<div class="image"><img :src="formdata.banner" /></div>
					</div>
					<WsUpload v-else :url="formdata.banner" @success="e => (formdata.banner = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="商品封面图" prop="productImg">
					<div class="details">
						<div class="image" v-for="(item, index) in formdata.productImg" :key="item">
							<img :src="item" />
							<span class="remove" @click="formdata.productImg.splice(index, 1)" v-if="!readonly"><a-icon type="delete" style="fontSize:28px;color:#ffffff" /></span>
						</div>
						<WsUpload class="upload" v-if="!readonly" @success="e => formdata.productImg.push(e.url)" style="display:block;" />
					</div>
				</a-form-model-item>
				<a-form-model-item label="商品视频" prop="video">
					<div class="details" v-if="readonly">
						<div class="video" v-if="formdata.video"><video :src="formdata.video"></video></div>
						<span v-else>暂无</span>
					</div>
					<WsUpload v-else type="video" :url="formdata.video ? formdata.video + '?vframe/png/offset/1' : ''" @success="e => (formdata.video = e.url)" />
				</a-form-model-item>
				<a-form-model-item label="商品详情" prop="details">
					<div class="details">
						<div class="image" v-for="(item, index) in formdata.details" :key="item">
							<img :src="item" />
							<span class="remove" @click="formdata.details.splice(index, 1)" v-if="!readonly"><a-icon type="delete" style="fontSize:28px;color:#ffffff" /></span>
						</div>
						<WsUpload class="upload" v-if="!readonly" @success="e => formdata.details.push(e.url)" />
					</div>
				</a-form-model-item>
				<a-form-model-item label="商品状态" prop="status">
					<span v-if="readonly">{{ formdata.status == 2 ? '禁用' : '启用' }}</span>
					<a-radio-group v-else v-model="formdata.status">
						<a-radio :value="1">启用</a-radio>
						<a-radio :value="2">禁用</a-radio>
					</a-radio-group>
				</a-form-model-item>
				<a-form-model-item :wrapper-col="{ span: 18, offset: 4 }" v-if="!readonly"><a-button type="primary" @click="onSubmit">保存</a-button></a-form-model-item>
			</a-form-model>
		</a-card>
	</a-space>
</template>

<script>
import { copy } from '@/utils';

import WsUpload from '@/components/WsUpload.vue';

const moneyRegexp = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
const numberRegexp = /^\d+$/;

export default {
	components: {
		WsUpload
	},
	data() {
		return {
			formdata: {
				id: '',
				banner: '',
				smallPic: '',
				productName: '',
				productPoint: '',
				productImg: [],
				salePrice: '',
				marketPrice: '',
				volume: '',
				shareNum: '',
				details: [],
				video: '',
				sort: '',
				status: 1,
				giveStart: '',
				giveMultiple: '',
				pickUpNum: '',
				pickUpStep: '',
				offlineUpgrade: '',
				offlineReplenish: '',
				sharePic: '',
				recommendedLanguage: ''
			},
			rules: {
				productName: [
					{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}
				],
				productPoint: [
					{
						required: true,
						message: '请输入商品特点',
						trigger: 'blur'
					}
				],
				sharePic:[
					{
						required: true,
						message: '请上传商品分享图',
						trigger: 'change'
					}
				],
				smallPic:[
					{
						required: true,
						message: '请上传商品封面图',
						trigger: 'change'
					}
				],
				banner: [
					{
						required: true,
						message: '请上传商品横幅图',
						trigger: 'change'
					}
				],
				productImg: [
					{
						type: 'array',
						required: true,
						message: '请上传商品封面图',
						trigger: 'change'
					}
				],
				details: [
					{
						type: 'array',
						required: true,
						message: '请上传商品详情图',
						trigger: 'change'
					}
				],
				salePrice: [
					{
						required: true,
						validator: (rule, value, callback) => {
							value && moneyRegexp.test(value) ? callback() : callback(new Error('请输入合法金额'));
						},
						trigger: 'blur'
					}
				],
				marketPrice: [
					{
						required: true,
						validator: (rule, value, callback) => {
							value && moneyRegexp.test(value) ? callback() : callback(new Error('请输入合法金额'));
						},
						trigger: 'blur'
					}
				],
				volume: [
					{
						required: true,
						validator: (rule, value, callback) => {
							value && numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				shareNum: [
					{
						required: true,
						validator: (rule, value, callback) => {
							value && numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				sort: [
					{
						required: true,
						validator: (rule, value, callback) => {
							value && numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				giveStart: [
					{
						required: true,
						validator: (rule, value, callback) => {
							numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				giveMultiple: [
					{
						required: true,
						validator: (rule, value, callback) => {
							numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				pickUpNum: [
					{
						required: true,
						validator: (rule, value, callback) => {
							numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				pickUpStep: [
					{
						required: true,
						validator: (rule, value, callback) => {
							numberRegexp.test(value) ? callback() : callback(new Error('请输入合法数字'));
						},
						trigger: 'blur'
					}
				],
				// offlineUpgrade: [
				// 	{
				// 		required: true,
				// 		message: '请上传线下购买礼包图片',
				// 		trigger: 'change'
				// 	}
				// ],
				// offlineReplenish: [
				// 	{
				// 		required: true,
				// 		message: '请上传线下补货图片',
				// 		trigger: 'change'
				// 	}
				// ]
			},
			layout: {
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 18
				}
			}
		};
	},
	computed: {
		cardTitle() {
			return this.$route.meta.title || '';
		},
		readonly() {
			const routeName = this.$route.name;
			return routeName == 'goodsView';
		}
	},
	async mounted() {
		const id = this.$route.params.id;
		if (id) {
			const response = await this.$api.get(`/goods_detail/${id}`);
			if (response && response.code == 200) {
				this.formdata = Object.assign(this.formdata, response.data, {
					id,
					productImg: response.data.productImg.split(','),
					details: response.data.details.split(',')
				});
			}
		}
	},
	methods: {
		onSubmit() {
			this.$refs.form.validate(async valid => {
				if (valid) {
					const data = copy(this.formdata);
					data.productImg = data.productImg.join(',');
					data.details = data.details.join(',');
					const response = await this.$api.post('/goods_update', data);
					if (response && response.code == 200) {
						this.$message.success('操作成功');
						setTimeout(() => {
							this.$router.back();
						}, 1000);
					}
				}
			});
		}
	}
};
</script>

<style lang="less">
.ant-cascader-menus,
.ant-select-dropdown {
	z-index: 1600;
}

.details {
	display: flex;
	flex-wrap: wrap;

	.upload {
		width: 128px;
		height: 128px;
	}

	.image,
	.video {
		position: relative;
		width: 128px;
		height: 128px;
		margin-right: 10px;
		margin-bottom: 10px;
		border: #eeeeee 1px dotted;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;

		.remove {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			background-color: rgba(0, 0, 0, 0.5);
			display: none;
			align-items: center;
			justify-content: center;
		}

		&:hover .remove {
			display: flex;
		}

		img,
		video {
			width: 128px;
			height: 128px;
			object-fit: contain;
		}
	}
}
</style>
