<template>
	<a-upload
		action="//upload-z2.qiniup.com"
		name="file"
		list-type="picture-card"
		class="avatar-uploader"
		:show-upload-list="false"
		:before-upload="onBeforeUpload"
		@change="onChange"
		:data="data"
	>
		
		<img class="image" v-if="type=='image' && url" :src="url" alt="avatar" />
		<div v-else-if="type=='audio' && url">
			<audio controls :src="url"></audio>
		</div>
		<!-- <img class="image" v-if="!!url" :src="url" alt="avatar" /> -->
		<div v-else><a-icon :type="loading ? 'loading' : 'plus'" /></div>
		<a class="zoom" :href="url" target="_blank" v-if="!!url && zoom" @click.stop><a-icon type="zoom-in" :style="{ fontSize: '20px', color: '#1890ff' }" /></a>
	</a-upload>
</template>
<script>
export default {
	props: {
		url: {
			type: String,
			default: ''
		},
		zoom: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'image'
		}
	},
	data() {
		return {
			loading: false,
			cdn: '',
			data: {
				token: '',
				key: ''
			}
		};
	},
	methods: {
		onChange(info) {
			console.info('upload.change', info);
			if (info.file.status === 'uploading') {
				this.loading = true;
				return;
			}
			if (info.file.status === 'done') {
				this.loading = false;
				const { response } = info.file;
				response.url = this.cdn + '/' + response.key;
				this.$emit('success', response);
			}
		},
		async onBeforeUpload(file) {
			console.info('file', file);
			if (this.type == 'image' && !/image\//.test(file.type)) {
				this.$message.error('请上传图片(png,jpg,jpeg)');
				return false;
			} else if (this.type == 'video' && !/video/.test(file.type)) {
				this.$message.error('请上传视频(mp4)');
				return false;
			} else if (this.type == 'audio' && !/audio/.test(file.type)) {
				this.$message.error('请上传音频(mp3)');
				return false;
			}
			if (this.type == 'image' && file.size > 1024 * 1024 * 3) {
				this.$message.error('请不要上传超过3MB!');
				return false;
			} else if (this.type == 'video' && file.size > 1024 * 1024 * 20) {
				this.$message.error('请不要上传超过20MB!');
				return false;
			} else if (this.type == 'audio' && file.size > 1024 * 1024 * 20) {
				this.$message.error('请不要上传超过20MB!');
				return false;
			}
			const response = await this.$api.get('qiniu/token');
			if (response && response.code == 200) {
				const { url, token } = response.data;
				this.cdn = url;
				const end = { image: '.png', video: '.mp4', audio: '.mp3' };
				this.data = {
					key: 'twl/upload/' + Date.now() + end[this.type],
					token
				};
				return true;
			}
			return Promise.reject(false);
		}
	}
};
</script>
<style lang="less">
.avatar-uploader {
	position: relative;

	.zoom {
		position: absolute;
		left: 160px;
		top: 120px;
	}
}

.avatar-uploader > .ant-upload {
	width: 128px;
	height: 128px;
}

.avatar-uploader > .ant-upload .image {
	width: 128px;
	height: 128px;
	max-width: 128px;
	max-height: 128px;
	object-fit: contain;
}

.ant-upload-select-picture-card i {
	font-size: 32px;
	color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
	margin-top: 8px;
	color: #666;
}
</style>
